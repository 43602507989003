<template>
  <div class="cate">
    <div class="option" v-if="success">
      <div class="title">{{total ? total :'0' }} <span style="font-size: 14px;color: #797979">个关于"<span style="font-weight: 700; color: #000;">{{keyword}}</span>"的结果</span></div>
    </div>
    <div v-else> 正在搜索... </div>
    <el-row :gutter="10" class="cate_content" v-if="searchList.length">
      <el-col style="font-weight: 700;">应用搜索列表</el-col>
      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="8" v-for="(item, index) in searchList" :key="item.id" >
        <SearchAppItem :time="item.updateTime"
              :type="item.categoryName" :currentPage="currentPage"
              :star="parseInt(item.score?item.score:0)"
               :name="item.name" :softId="item.id" :size="item.appSize" :iconUrl="item.logoUrl"/>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="cate_content" v-if="searchArticleList.length">
      <el-col style="font-weight: 700;">帖子搜索列表</el-col>
      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="8" v-for="item in searchArticleList" :key="item.id" >
        <ArticleListItem :key="item.id"  :currentPage="currentPage"
                         :title="item.title" content="content" :createTime="item.createTime" :updateTime="item.updateTime" :summary="item.summary" :imagePath="item.imagePath" :article_id="item.id"/>
      </el-col>
    </el-row>
<!--    <el-row :gutter="10" class="cate_content" v-if="searchArticleList.length">-->
<!--      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="7" v-for="item in searchArticleList" :key="item.id">-->
<!--        <SpeakItem :title="item.title" :imagePath="item.imagePath" :summary="item.summary"-->
<!--                   @click.native="$router.push(`/detailPost/${item.id}`)"/>-->
<!--      </el-col>-->
<!--    </el-row>-->
    <!--<div class="cate_content">
      <Item  v-for="(item, index) in searchList" :key="item.id" :time="item.updateTime"
            :name="item.name" :softId="item.id" :size="item.appSize" :iconUrl="item.logoUrl"/>
    </div>-->
    <div class="page_">
      <el-pagination
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import SearchAppItem from '@/components/item/SearchAppItem.vue'
  import ArticleListItem from '@/components/item/ArticleListItem'
  import { getSearcherSoft,getArticleSearcherSoft } from '@/utils/network'
  export default {
   data() {
     return{
       searchList:[],
       searchArticleList:[],
       //分页
       currentPage:this.$store.state.isLastPage?parseInt(this.$store.state.lastPage):1,
       pageSize:12,
       total:null,
       success:false
     }
   },
    created() {
     this.getData()
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
    },
    watch:{
      keyword(value) {
        this.currentPage = 1
        this.getData()
        window.scrollTo(0, 0);
      }
    },
    methods:{
      //分页切换
      currentChange(index) {
        this.currentPage = index
        this.getData()
        window.scrollTo(0, 0);
      },
      async getData() {
        if (this.keyword.trim() === '')  return
        this.success = false
        const {data: res} = await getSearcherSoft({
          search_content: this.keyword,
          page:this.currentPage,
          per_page:this.pageSize,
          sourceType:'web'
        })
        if (res.code !== 200) this.$message.error('请求失败')
        const {data: res1} = await getArticleSearcherSoft({
          search_content: this.keyword,
          page:this.currentPage,
          per_page:this.pageSize
        })
        if (res1.code !== 200) this.$message.error('请求失败')
        this.total = res.data.total+res1.data.total
        if(res.data.total<=12&&this.currentPage!==1){
          this.searchList = [];
        }else{
          this.searchList = res.data.vodata
        }
        if(res1.data.total<=12&&this.currentPage!==1){
          this.searchArticleList = [];
        }else{
          this.searchArticleList = res1.data.vodata
        }
        this.success = true
      },
    },
    computed:mapState(['keyword']),
    components: {
      SearchAppItem,ArticleListItem
    }
  }
</script>

<style lang="less" scoped>
  .cate {
    width: 80%;
    min-height: 670px;
    margin: 20px auto;
    background-color: #fff;
    overflow: hidden;
    padding: 0 12px;
    .option {
      display: flex;
      //margin: 10px;
      height: 40px;
      line-height: 40px;
      justify-content: space-between;

      .title {
        font-weight: 700;
      }

      .op_tol {
        display: flex;
        width: 180px;
        margin-top: 20px;
        justify-content: space-around;
        font-size: 14px;
        color: rgba(0, 0, 0, .6);
      }
    }

    .cate_content {
      //display: flex;
      width: 100%;
      //flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }

</style>
