<template>
  <div class="ev_item" @click="goDetail">
    <div class="ev_left">
      <img :src="iconUrl" alt="" width="140" height="128">
    </div>
    <div class="ev_right">
      <div class="ev_right_top overflow" style="font-weight: 700; font-size: 18px; margin-bottom: 4px;">{{name}}</div>
      <div>推荐星级：{{star===0?'':star}}</div>
      <div>大小：{{size}}</div>
      <div>更新时间：{{time | dataFormat}}</div>
      <div>类型：{{type}}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SearchAppItem',
  data() {
    return {}
  },
  props:{
    imgUrl:{
      type:String
    },
    star:{
      type:String | Number, // 类型是string 或者是 number
      default:''
    },
    size:{
      type:String,
    },
    time:{
      type:String,

    },
    type:{
      type:String
    },
    name:{
      type:String
    },
    currentPage:{
      type:Number
    },
    softId:{
      type:Number,
      required:true
    },
    iconUrl:{
      type:String
    }


  },
  methods: {
    goDetail() {
      this.$store.dispatch('lastPage', {
        lastPage: `${this.currentPage}`
      })
      this.$router.push(`/detail/${this.softId}`)
    }

  },
  components: {}
}
</script>

<style lang="less" scoped>
.ev_item {
  padding: 12px 6px;
  min-width: 298px;
  //max-width: 380px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  margin-left: 18px;
  width:94%;
  justify-content: space-around;
  margin-bottom: 12px;
  border: solid 1px #c8c8c8;
  &:hover {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  }
  .ev_right {
    font-size: 14px;
    width: 67%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //align-items: first;
  }
  .ev_left {
    img {
      object-fit: cover;
    }
  }

}

</style>
